import React from "react"
import { Row, Col, Modal, Button } from "react-bootstrap"
import "../styles/main.scss"
import Fbdark from "../images/fb-dark.svg"
import Indark from "../images/in-dark.svg"

import Advice1 from "../images/advice-add-001.jpg"
import Advice2 from "../images/advice-add-002.jpg"
import Advice3 from "../images/advice-add-003.jpg"
import Advice4 from "../images/advice-add-004.jpg"
import Advice5 from "../images/advice-add-005.jpg"

import Brand from "../images/brand.png"
import { translate } from "../utils"

const ModalFour = () => {
  return (
    <div className="modal-wrap">
      <Modal.Dialog>
        <Modal.Header
          className="modal-blue-alpha"
          closeButton
          style={{
            backgroundImage: `url("https://source.unsplash.com/1920x400/?mountain")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "auto",
            color: "white",
          }}
        >
          <Modal.Title>
            <span className="title-tag d-inline">{translate("avantages")}</span>
            <span className="d-block">
              {translate('le coworking, ou comment développer son réseau')}?
            </span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md="7" className="pr-5">
              <div className="orange-widgets-box-profile rounded-lg mt-4 mb-5  text-center">
                <Row className="align-items-center">
                  <Col xl="6">
                    <h1 className="h1-title">
                      {translate("je désire profiter de cette avantage")}!
                    </h1>
                  </Col>
                  <Col xl="6">
                    <Button variant="outline-secondary" className="button-yellow" >{translate('j’en profite')}!</Button>
                  </Col>
                </Row>
              </div>
              <h2>{translate("reclaim Your Creative Confidence")}</h2>
              <h3>{translate('example of advantage')}</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                volutpat ipsum id bibendum commodo. Etiam tincidunt ante ut ante
                dignissim viverra. Proin pharetra lectus vel augue elementum, at
                egestas dui iaculis. Mauris ac sem odio. Vivamus id elit sed
                tellus condimentum posuere. Etiam conv
                <strong>
                  allis ac ipsum non tempus. Vivamus orci erat, elementum eget
                  malesuada ut, fringilla ut elit. Donec velit mi, malesuada
                  vitae convallis eleifend, moles
                </strong>
                tie eget nulla.
              </p>
              <p>
                Praesent pulvinar sollicitudin est in tristique. Nulla purus
                leo, varius non pharetra nec, iaculis eu velit. Sed justo mi,
                molestie sit amet nisl nec, cursus vestibulum sem. Nunc non
                volutpat erat, nec mattis ex. Sed fermentum elit sem, id luctus
                nisl fermentum ut. Aliquam efficitur ac
                <strong>
                  {" "}
                  massa in venenatis. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Nullam malesuada aliquet nulla, id sodales
                  mi. Sed congue nunc sem, non tincidunt velit lobortis eu.
                  Donec eget est ac nibh vestibulum laoreet eu aliquam dolor.
                  Etiam venenatis mauris et malesuada gravida. Sed interdum eros
                  id sem fermentum, eu lacinia ligula vulputate.{" "}
                </strong>
              </p>
              <a className="btn btn-outline-danger mb-4" href="#">
                {translate("I want this advantage")}
              </a>
              <Row>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice1} alt="" />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice2} alt="" />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice3} alt="" />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice4} alt="" />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice5} alt="" />
                  </div>
                </Col>
              </Row>
              <a className="btn btn-outline-danger" href="#">
                {translate("I want this advantage")}
              </a>
            </Col>
            <Col md="4" className="offset-md-1 modal-sidebar">
              <h3>Partner</h3>
              <img className="img-fluid" src={Brand} alt="" />
              <div className="my-4">
                <h4>Proximus S.A.</h4>
                <a href={"#"} className="btn btn-outline-dark">
                  + {translate('details about partner')}
                </a>
              </div>
              <div className="my-4">
                <h3 className="mb-0">{translate('advantage details')}</h3>
                <small>Countdown: 15 days _ 3 hours _ 26 min</small>
              </div>
              <h3>{translate('share')}</h3>
              <ul className="list-none m-0 ui-social-linline">
                <li>
                  <a href={''}>
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={Fbdark}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href={''}>
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={Indark}
                      alt="Linkedin"
                    />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  )
}

export default ModalFour
